import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Component, Inject, Input, Output, forwardRef, Directive, NgModule } from '@angular/core';
import Masonry from 'masonry-layout';
import * as i1 from '@angular/animations';
import { style, animate } from '@angular/animations';
const _c0 = ["ngx-masonry", ""];
const _c1 = ["*"];
class NgxMasonryComponent {
  constructor(platformId, _element) {
    this.platformId = platformId;
    this._element = _element;
    this.updateLayout = false;
    this.ordered = false;
    // Outputs
    this.layoutComplete = new EventEmitter();
    this.removeComplete = new EventEmitter();
    this.itemsLoaded = new EventEmitter();
    this.pendingItems = [];
  }
  ngOnInit() {
    // Create masonry options object
    if (!this.options) {
      this.options = {};
    }
    // Set default itemSelector
    if (!this.options.itemSelector) {
      this.options.itemSelector = '[ngxMasonryItem], ngxMasonryItem';
    }
    this.options['transitionDuration'] = '0s';
    if (isPlatformBrowser(this.platformId)) {
      // Initialize Masonry
      this.masonryInstance = new Masonry(this._element.nativeElement, this.options);
      // Bind to events
      this.masonryInstance.on('layoutComplete', items => {
        this.layoutComplete.emit(items);
      });
      this.masonryInstance.on('removeComplete', items => {
        this.removeComplete.emit(items);
      });
      this.masonryInstance.items = [];
    }
  }
  ngOnChanges(changes) {
    // only update layout if it's not the first change
    if (changes.updateLayout) {
      if (!changes.updateLayout.firstChange) {
        this.layout();
      }
    }
  }
  ngOnDestroy() {
    if (this.masonryInstance) {
      this.masonryInstance.destroy();
    }
  }
  layout() {
    setTimeout(() => {
      this.masonryInstance.layout();
    });
  }
  reloadItems() {
    setTimeout(() => {
      this.masonryInstance.reloadItems();
    });
  }
  addPendingItem(item) {
    this.pendingItems.push(item);
  }
  add(newItem) {
    if (this.ordered) {
      for (const [index, item] of this.pendingItems.entries()) {
        if (item) {
          if (item.images && item.images.size === 0) {
            this.pendingItems[index] = undefined;
            this.itemLoaded(item);
            if (index + 1 === this.pendingItems.length) {
              // All items are loaded
              this.itemsLoaded.emit(this.pendingItems.length);
              this.pendingItems = [];
            }
          } else {
            return;
          }
        }
      }
    } else {
      this.itemLoaded(newItem);
    }
  }
  itemLoaded(item) {
    if (isPlatformBrowser(this.platformId)) {
      // Tell Masonry that a child element has been added
      if (item.prepend) {
        this.masonryInstance.prepended(item.element.nativeElement);
      } else {
        this.masonryInstance.appended(item.element.nativeElement);
      }
      // Check if first item
      if (this.masonryInstance.items.length === 1) {
        this.masonryInstance.layout();
      }
      item.playAnimation(true);
    }
  }
  remove(element) {
    if (isPlatformBrowser(this.platformId)) {
      // Tell Masonry that a child element has been removed
      this.masonryInstance.remove(element);
      // Layout items
      this.layout();
    }
  }
}
NgxMasonryComponent.ɵfac = function NgxMasonryComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxMasonryComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef));
};
NgxMasonryComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxMasonryComponent,
  selectors: [["", "ngx-masonry", ""], ["ngx-masonry"]],
  inputs: {
    options: "options",
    updateLayout: "updateLayout",
    ordered: "ordered"
  },
  outputs: {
    layoutComplete: "layoutComplete",
    removeComplete: "removeComplete",
    itemsLoaded: "itemsLoaded"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  attrs: _c0,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function NgxMasonryComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{display:block}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMasonryComponent, [{
    type: Component,
    args: [{
      selector: '[ngx-masonry], ngx-masonry',
      template: '<ng-content></ng-content>',
      styles: [":host{display:block}\n"]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }];
  }, {
    options: [{
      type: Input
    }],
    updateLayout: [{
      type: Input
    }],
    ordered: [{
      type: Input
    }],
    layoutComplete: [{
      type: Output
    }],
    removeComplete: [{
      type: Output
    }],
    itemsLoaded: [{
      type: Output
    }]
  });
})();
class NgxMasonryDirective {
  constructor(element, builder, parent, renderer) {
    this.element = element;
    this.builder = builder;
    this.parent = parent;
    this.renderer = renderer;
    this.prepend = false;
    this.animations = {
      show: [style({
        opacity: 0
      }), animate('400ms ease-in', style({
        opacity: 1
      }))],
      hide: [style({
        opacity: '*'
      }), animate('400ms ease-in', style({
        opacity: 0
      }))]
    };
  }
  ngOnInit() {
    if (this.parent.options.animations !== undefined) {
      this.animations = this.parent.options.animations;
    }
    this.renderer.setStyle(this.element.nativeElement, 'position', 'fixed');
    this.renderer.setStyle(this.element.nativeElement, 'right', '-150vw');
    this.parent.addPendingItem(this);
  }
  ngAfterViewInit() {
    const images = Array.from(this.element.nativeElement.getElementsByTagName('img'));
    this.images = new Set(images);
    if (images.length === 0) {
      setTimeout(() => {
        this.parent.add(this);
      });
    } else {
      for (const imageRef of images) {
        // skip image render check if image has `masonryLazy` attribute
        if (imageRef.hasAttribute('masonryLazy')) {
          this.imageLoaded(imageRef);
        } else {
          this.renderer.listen(imageRef, 'load', _ => {
            this.imageLoaded(imageRef);
          });
          this.renderer.listen(imageRef, 'error', _ => {
            this.imageLoaded(imageRef);
          });
        }
      }
    }
  }
  ngOnDestroy() {
    if (this.images && this.images.size === 0 && this.element.nativeElement.parentNode) {
      this.playAnimation(false);
      this.parent.remove(this.element.nativeElement);
    }
  }
  imageLoaded(image) {
    this.images.delete(image);
    if (this.images.size === 0) {
      this.parent.add(this);
    }
  }
  playAnimation(show) {
    const metadata = show ? this.animations.show : this.animations.hide;
    if (metadata) {
      const player = this.builder.build(metadata).create(this.element.nativeElement);
      player.play();
    }
  }
}
NgxMasonryDirective.ɵfac = function NgxMasonryDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxMasonryDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.AnimationBuilder), i0.ɵɵdirectiveInject(forwardRef(() => NgxMasonryComponent)), i0.ɵɵdirectiveInject(i0.Renderer2));
};
NgxMasonryDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NgxMasonryDirective,
  selectors: [["", "ngxMasonryItem", ""], ["ngxMasonryItem"]],
  inputs: {
    prepend: "prepend"
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMasonryDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxMasonryItem], ngxMasonryItem'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.AnimationBuilder
    }, {
      type: NgxMasonryComponent,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => NgxMasonryComponent)]
      }]
    }, {
      type: i0.Renderer2
    }];
  }, {
    prepend: [{
      type: Input
    }]
  });
})();
class NgxMasonryModule {}
NgxMasonryModule.ɵfac = function NgxMasonryModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxMasonryModule)();
};
NgxMasonryModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxMasonryModule
});
NgxMasonryModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMasonryModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [NgxMasonryComponent, NgxMasonryDirective],
      exports: [NgxMasonryComponent, NgxMasonryDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-masonry
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMasonryComponent, NgxMasonryDirective, NgxMasonryModule };
